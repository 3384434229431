import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Header from './layout/Header'
import { Home, Language, Login, Rate, Terms } from './pages'
import './App.css';

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: "360 Feedback Survey",
      showLogout: false
    }
  }

  setTitle = (title) => {
    this.setState({
      title: title
    })
  }

  showLogout = (show) => {
    this.setState({
      showLogout: show
    })
  }

  getLanguage = () => {
    let language = localStorage.getItem('language');
    return ['hr', 'cz', 'en', 'hu', 'ru', 'sk'].indexOf(language) !== -1 ? language : 'en';
  }

  render() {
    return (
      <Router>
        <div className="app">
          <Header title={this.state.title} getLanguage={this.getLanguage} showLogout={this.state.showLogout} />
          <div className="wrapper">
            <Route path="/login" render={(props) => (
              <Login {...props} getLanguage={this.getLanguage} showLogout={this.showLogout} /> )} />
            <Route exact path="/en" component={Language} />
            <Route exact path="/hu" component={Language} />
            <Route exact path="/hr" component={Language} />
            <Route exact path="/ru" component={Language} />
            <Route exact path="/sk" component={Language} />
            <PrivateRoute exact getLanguage={this.getLanguage} showLogout={this.showLogout} path="/rate/:id" component={Rate} />
            <PrivateRoute exact getLanguage={this.getLanguage} showLogout={this.showLogout} path="/terms" component={Terms} />
            <PrivateRoute exact path="/" component={Home} setTitle={this.setTitle} getLanguage={this.getLanguage} showLogout={this.showLogout} />
          </div>
        </div>
      </Router>
    );
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('token') ? (
        <Component getLanguage={rest.getLanguage} setTitle={rest.setTitle} showLogout={rest.showLogout} {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
)

export default App;
