import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Languages from '../components/Languages';
import API from '../services/api';
import Loader from '../components/Loader';

export default class Terms extends Component {
	constructor(props) {
		super(props);

		let language = localStorage.getItem('language');

		this.state = {
			isLoading: true,
			email: "",
			password: "",
			accept: "no",
			termsAccepted: false,
			termsDeclined: false,
			page: null,
			language: ['en', 'cz', 'hr', 'hu', 'ru', 'sk', 'si'].indexOf(language) ? language : 'en',
			languages: [],
			t: {},
		}
	}

	componentDidMount() {
		this.loadData();
		this.props.showLogout(true);
	}

	loadData = async () => {
		await API.get('', {
			params: {
				action: 'languages'
			}
		}).then(response => {
			let data = response.data;
			let currentLanguage = this.state.language;
			let languageFound = false;

			for (let i in data) {
				if (data[i].id === currentLanguage) {
					languageFound = true;
				}
			}

			if (!languageFound) {
				localStorage.setItem('language', data[0].id);
				window.location.reload();
				return false;
			}

			this.setState({
				languages: data
			});
		}).catch(error => {

		});

		await API.get('', {
			params: {
				action: 'translations',
				slug: 'home',
				language: this.props.getLanguage()
			}
		}).then(response => {
			this.setState({
				t: response.data,
			});
		}).catch(error => {

		});

		await API.get('', {
			params: {
				action: 'researchTerms',
				language: this.props.getLanguage()
			}
		}).then(response => {
			const data = response.data;

			this.setState({
				isLoading: false,
				page: data,
				termsAccepted: (data.terms_accepted_at !== null)
			});
		}).catch(error => {

		});
	}

	_onFormSubmit = async (event) => {
		event.preventDefault();

		let termsAccepted = this.state.accept === "yes";

		await API.post('', {
			action: 'researchTermsSubmit',
			accepted: termsAccepted
		}).then(response => {
			if (termsAccepted) {
				this.setState({
					termsAccepted: true
				})
			} else {
				this.setState({
					termsDeclined: true
				})
			}
		}).catch(error => {

		});
	}

	_onInputChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	render() {
		const {
			termsDeclined,
			termsAccepted,
			isLoading,
			page,
			t
		} = this.state;


		if (termsDeclined) {
			return <Redirect to='/login' />;
		}

		if (termsAccepted) {
			return <Redirect to='/' />;
		}

		if (isLoading) {
			return <Loader />;
		}

		return (
			<div className="page">
				<Languages />
				<h1 className="page__heading">
					{t.home_terms_title}
				</h1>
				<div className="page__content">
					<article className="preview" dangerouslySetInnerHTML={{ __html: page.terms }} />
					<article dangerouslySetInnerHTML={{ __html: page.terms_intro }} />
					<form className="form" method="post" onSubmit={this._onFormSubmit}>
						<fieldset className="fieldset">
							<div>
								<input type="radio" name="accept" value="yes" onChange={this._onInputChange} />
								<div className="terms__option" dangerouslySetInnerHTML={{ __html: page.terms_agree }} />
							</div>
						</fieldset>
						<fieldset className="fieldset">
							<div>
								<input type="radio" name="accept" value="no" onChange={this._onInputChange} />
								<div className="terms__option" dangerouslySetInnerHTML={{ __html: page.terms_disagree }} />
							</div>
						</fieldset>
						<fieldset className="fieldset">
							<button type="submit" className="button">{t.home_submit}</button>
						</fieldset>
					</form>
				</div>
			</div>
		)
	}
}