import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Loader from '../components/Loader';
import API from '../services/api';

export default class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			email: "",
			password: "",
			loggedIn: false,
			error: false,
			page: null,
			t: {},
		}
	}

	getLanguage = () => {
		let language = localStorage.getItem('language');
		return ['hr', 'cz', 'en', 'hu', 'ru', 'sk'].indexOf(language) !== -1 ? language : 'en';
	}

	componentWillMount() {
		localStorage.removeItem('token');
		localStorage.removeItem('terms');
	}

	componentDidMount() {
		this.loadData();
		console.log(this.props);
		this.props.showLogout(false);
	}

	loadData = async () => {
		const pageId = window.location.hostname.indexOf('survey.') !== -1 ? 'login-satisfaction' : 'login';

		await API.get('', {
			params: {
				action: 'page',
				slug: pageId,
				language: this.getLanguage(),
			}
		}).then(response => {
			this.setState({
				page: response.data,
				isLoading: false,
			});
		}).catch(error => {

		});

		await API.get('', {
			params: {
				action: 'translations',
				slug: 'login',
				language: this.getLanguage()
			}
		}).then(response => {
			this.setState({
				t: response.data,
				isLoading: false,
			});
		}).catch(error => {

		});
	}

	_onFormSubmit = async (event) => {
		event.preventDefault();

		this.setState({
			isLoggingIn: true,
			error: false
		})

		await API.post('', {
			action: 'surveyLogin',
			email: this.state.email,
			password: this.state.password
		}).then(response => {
			if (response.data.token) {
				localStorage.setItem('token', response.data.token);
				localStorage.setItem('language', response.data.user.language);

				this.setState({
					loggedIn: true
				});
			}
		}).catch(error => {
			this.setState({
				error: true
			});
		});

		this.setState({
			isLoggingIn: false
		})
	}

	_onInputChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	render() {

		let {
			isLoading,
			page,
			t,
			error,
		} = this.state;

		if (this.state.loggedIn) {
			return <Redirect to='/terms' />
		}

		if (isLoading) {
			return <Loader />
		}

		return (
			<div className="page">
				<h1 className="page__heading">
					{page.title}
				</h1>
				<div className="page__content">
					<article className="article" dangerouslySetInnerHTML={{ __html: page.content }} />
					<form className="form form--login" method="post" onSubmit={this._onFormSubmit}>
						{error &&
							<p className="error">{t.login_failed}</p>
						}
						<fieldset className="fieldset">
							<label className="label">{t.login_email}</label>
							<input type="email" required="required" name="email" className="input" value={this.state.email} onChange={this._onInputChange} />
						</fieldset>
						<fieldset className="fieldset">
							<label className="label">{t.login_password}</label>
							<input type="password" required="required" name="password" className="input" value={this.state.password} onChange={this._onInputChange} />
						</fieldset>
						<fieldset className="fieldset">
							<button type="submit" className="button">{this.state.isLoggingIn ? t.login_please_wait : t.login_submit}</button>
						</fieldset>
					</form>
				</div>
			</div>
		)
	}
}