import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Auth from '../services/Auth';
import API from '../services/api';

class Header extends Component {
	constructor(props) {
		super(props)

		this.state = {
			mobileMenuOpen: false,
			isLoading: true,
			isLoggedIn: Auth.isLoggedIn(),
			showLogout: false,
			backToHome: false
		}
	}

	componentDidMount() {
		this.loadData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.showLogout !== prevProps.showLogout) {
			this.setState({
				showLogout: this.props.showLogout
			})
		}
	}

	loadData = async () => {
		await API.get('', {
			params: {
				action: 'translations',
				slug: 'va_header',
				language: this.props.getLanguage()
			}
		}).then(response => {
			this.setState({
				t: response.data,
				isLoading: false,
			});
		}).catch(error => {

		});
	}

	onMobileMenuClick = () => {
		this.setState({
			mobileMenuOpen: !this.state.mobileMenuOpen
		})
	}

	hideMobileMenu = () => {
		this.setState({
			mobileMenuOpen: false
		})
	}

	render() {
		let headerClassNames = ["header"];

		if (this.state.mobileMenuOpen) {
			headerClassNames.push("header--open");
		}

		const {
			t,
			isLoading
		} = this.state;

		if (isLoading) {
			return <></>;
		}

		return (
			<header className={headerClassNames.join(" ")}>
				<div className="wrapper">
					{this.state.showLogout &&
						<div className="header__logout">
							<NavLink onClick={this.hideMobileMenu} exact to="/login">
								{t.va_header_logout}
							</NavLink>
						</div>
					}
					<div className="header__logo">
						<NavLink onClick={this.hideMobileMenu} exact to="/">
							<img alt="Gentis" src="/assets/images/gentis.svg" />
						</NavLink>
					</div>
					<div className="mobile-menu">
						<img onClick={this.onMobileMenuClick} className="mobile-menu__trigger" alt="" src="/assets/images/menu.svg" />
					</div>
				</div>
			</header>
		)
	}
}

export default withRouter(Header)