import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import API from '../services/api';

class Languages extends Component {
  constructor(props) {
    super(props);

    let language = localStorage.getItem('language');

    if (!language) {
      language = 'en';
    }

    this.state = {
      data: null,
      isLoading: true,
      language: ['en', 'cz', 'hr', 'hu', 'ru', 'sk', 'si'].indexOf(language) ? language : 'en',
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.showLogout !== prevProps.showLogout) {
      this.setState({
        showLogout: this.props.showLogout
      })
    }
  }

  loadData = async () => {
    await API.get('', {
      params: {
        action: 'languages'
      }
    }).then(response => {
      let data = response.data;

      this.setState({
        data: data,
        isLoading: false,
      });
    }).catch(error => {

    });
  }

  _setLanguage = (event) => {
    localStorage.setItem('language', event.target.dataset.language);

    window.location.reload();
  }

  render() {
    const {
      data,
      isLoading,
      language
    } = this.state;

    if (isLoading || data.length <= 1) {
      return <></>;
    }

    return (
      <div className="tabs">
        {data.map((item, key) => (
          <div key={key} onClick={this._setLanguage} data-language={item.id} className={`tabs__tab${language === item.id ? ' tabs__tab--active' : ''}`}>{item.name}</div>
        ))}
      </div>
    )
  }
}

export default withRouter(Languages)