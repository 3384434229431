import React, { Component } from 'react'

export default class Loader extends Component {
	render() {
		return (
			<div className="page__loader">
				<p><img alt="Loading..." src="/assets/images/loader.gif" /></p>
			</div>
		)
	}
}