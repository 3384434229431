import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export default class Language extends Component {
  constructor(props) {
    super(props);

    alert(window.location.pathname.substring(1).toLowerCase());

    let language = window.location.pathname.substring(1).toLowerCase();

    if (!['en', 'cz', 'hr', 'hu', 'ru', 'sk', 'si'].indexOf(language)) {
      language = 'en';
    }

    localStorage.setItem('language', language);
  }

  render() {
    return <Redirect to='/' />
  }
}