import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Loader from '../components/Loader';
import Languages from '../components/Languages';
import API from '../services/api';
import moment from 'moment';

export default class Home extends Component {
	constructor(props) {
		super(props);

		let language = localStorage.getItem('language');

		this.state = {
			isLoading: true,
			page: null,
			modalOpen: false,
			me: null,
			name: "",
			email: "",
			lang: null,
			language: ['en', 'cz', 'hr', 'hu', 'ru', 'sk', 'si'].indexOf(language) !== -1 ? language : 'en',
			languages: [],
			research: null,
			researchList: [],
			relation: 0,
			relations: [],
			raters: [],
		}
	}

	componentDidMount() {
		this.loadData()
		this.props.showLogout(true)
	}

	loadData = async () => {
		let research;

		await API.get('', {
			params: {
				action: 'me'
			}
		}).then(response => {
			this.setState({
				me: response.data
			});
		}).catch(error => {

		});

		await API.get('', {
			params: {
				action: 'languages'
			}
		}).then(response => {
			let data = response.data;
			let currentLanguage = this.state.language;
			let languageFound = false;

			for (let i in data) {
				if (data[i].id === currentLanguage) {
					languageFound = true;
				}
			}

			if (!languageFound) {
				localStorage.setItem('language', data[0].id);
				window.location.reload();
				return false;
			}

			this.setState({
				languages: data
			});
		}).catch(error => {

		});

		await API.get('', {
			params: {
				action: 'research',
				language: this._getCurrentLanguage(),
			}
		}).then(response => {
			research = response.data;

			this.setState({
				research: research
			});
		}).catch(error => {

		});

		await API.get('', {
			params: {
				action: 'researchList',
				language: this._getCurrentLanguage(),
			}
		}).then(response => {
			this.setState({
				researchList: response.data
			});
		}).catch(error => {

		});

		await API.get('', {
			params: {
				action: 'home',
				language: this.props.getLanguage(),
			}
		}).then(response => {
			this.setState({
				page: response.data,
			});
		}).catch(error => {

		});

		await API.get('', {
			params: {
				action: 'translations',
				slug: 'home',
				language: this.props.getLanguage()
			}
		}).then(response => {
			this.setState({
				t: response.data,
			});
		}).catch(error => {

		});

		if (research.mode === 'nomination') {
			await API.get('', {
				params: {
					action: 'relations',
					researchId: research.id,
					language: this._getCurrentLanguage(),
				}
			}).then(response => {
				this.setState({
					relations: response.data
				});
			}).catch(error => {

			});

			await this._loadRaters();
		}


		this.setState({
			isLoading: false,
		});
	}

	_loadRaters = async () => {
		await API.get('', {
			params: {
				action: 'raters',
				researchId: this.state.research.id,
				language: this._getCurrentLanguage(),
			}
		}).then(response => {
			this.setState({
				raters: response.data
			});
		}).catch(error => {

		});
	}

	_inviteOnClick = (event) => {
		this.setState({
			modalOpen: true
		});
	}

	_inviteOnClose = (event) => {
		this.setState({
			modalOpen: false
		});
	}

	_inviteOnSubmit = async (event) => {
		event.preventDefault();

		await API.post('', {
			action: 'raterSubmit',
			name: this.state.name,
			email: this.state.email,
			researchId: this.state.research.id,
			relation: parseInt(this.state.relation, 10),
			lang: this._getLanguage(this.state.lang),
		}).then(response => {

		}).catch(error => {
			window.alert(this._t('home_generic_error'));
		});

		this.setState({
			modalOpen: false,
		});

		await this._loadRaters();
	}

	_inviteOnRemove = async (event) => {
		if (!window.confirm(this._t('home_remove_rater_confirm'))) {
			return false;
		}

		await API.post('', {
			action: 'raterRemove',
			id: event.target.dataset.id,
		}).then(response => {
			this._loadRaters();
		}).catch(error => {
			window.alert(this._t('home_generic_error'));
		});
	}

	_inputOnChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	_getReleationTitle = (id) => {
		let relations = this.state.relations;

		for (let i in relations) {
			if (relations[i].id === id) {
				return relations[i].content;
			}
		}
	}

	_getCurrentLanguage = () => {
		return this._getLanguage(localStorage.getItem('language'));
	}

	_getLanguage = (id) => {
		let languages = this.state.languages;

		for (let i in languages) {
			if (languages[i].id === id) {
				return languages[i].id;
			}
		}

		return languages[0].id;
	}


	_t = (key) => {
		return this.state.t[key];
	}

	render() {
		const {
			isLoading,
			modalOpen,
			raters,
			relations,
			me,
			name,
			email,
			researchList,
			relation,
			lang,
			languages,
			page,
			t
		} = this.state

		if (isLoading) {
			return <Loader />;
		}

		return (
			<div className="page">
				<Languages />
				<h1 className="page__heading">
					{t.home_welcome} {me.name}!
				</h1>
				<div className="page__content">
					{page !== null &&
						<article className="page__intro" dangerouslySetInnerHTML={{ __html: page.content }} />
					}
					{researchList.map((item, key) => (
						<div key={key}>
							<h2>{item.title}</h2>
							<p>{item.description}</p>
							{item.mode === 'nomination' ? (
								<div className="research__nomination">
									{raters.length === 0 ? (
										<p>{t.home_no_raters}</p>
									) : (
										<div>
											<h3>{t.home_your_raters}</h3>
											<table className="table">
												<thead>
													<tr>
														<th>{t.home_name}</th>
														<th>{t.home_email}</th>
														<th>{t.home_relation}</th>
														<th>{t.home_language}</th>
													</tr>
												</thead>
												<tbody>
													{raters.map((item, key) => (
														<tr key={key}>
															<td>{item.name}</td>
															<td>{item.email}</td>
															<td>{this._getReleationTitle(item.relation)}</td>
															<td>{item.language}</td>
															<td className="table__cell--clear"><span data-id={item.id} className="remove" onClick={this._inviteOnRemove} /></td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									)
									}
									<p className="research__nomination__info"><em>{t.home_rater_deadline} <strong>{moment(item.invite_ends_at).format(t.generic_date_format)}</strong></em></p>
									<div>
										<span className="button" to="/invite" onClick={this._inviteOnClick}>{t.home_add_rater}</span>
									</div>
								</div>
							) : (
								<div className="research__survey">
									{item.surveys.length > 0 ? (
										<div className="research__survey__list">
											{item.surveys.map((survey, key) => (
												<div key={key} className={`research__survey__item ${item.completeness === 100 ? ' research__survey__item--completed' : ''}`}>
													<h3><NavLink to={`/rate/${survey.id}`}>{(survey.relation === null && item.relation_type !== 'satisfaction') ? t.generic_self_assessment : survey.user}</NavLink></h3>
													<h4>{survey.questionnaire}</h4>
													<span className="research__survey__item__completeness">{survey.completeness}<span>%</span></span>
												</div>
											))}
										</div>
									) : (
										<p className="reserarch__survey_info error">{t.home_survey_missing}</p>
									)}
								</div>
							)
							}
						</div>
					))}
				</div>
				{modalOpen &&
					<div className="modal">
						<div className="modal__content">
							<form className="form" onSubmit={this._inviteOnSubmit}>
								<fieldset className="fieldset">
									<label className="label">{t.home_name}</label>
									<input type="text" required={true} name="name" className="input" value={name} onChange={this._inputOnChange} />
								</fieldset>
								<fieldset className="fieldset">
									<label className="label">{t.home_email}</label>
									<input type="email" required={true} name="email" className="input" value={email} onChange={this._inputOnChange} />
								</fieldset>
								<fieldset className="fieldset">
									<label className="label">{t.home_relation}</label>
									<select required={true} name="relation" className="input" value={relation} onChange={this._inputOnChange}>
										<option value="">{t.home_please_select}</option>
										{relations.map((item, key) => (
											<option key={key} value={item.id}>{item.content}</option>
										))}
									</select>
								</fieldset>
								{languages.length > 1 &&
									<fieldset className="fieldset">
										<label className="label">{t.home_language}</label>
										<select required={true} name="lang" className="input" value={lang} onChange={this._inputOnChange}>
											{languages.map((item, key) => (
												<option key={key} value={item.id}>{item.name}</option>
											))}
										</select>
									</fieldset>
								}
								<fieldset className="fieldset">
									<button type="submit" className="button">{t.home_add_rater}</button>
								</fieldset>
							</form>
						</div>
						<div className="modal__close" onClick={this._inviteOnClose} />
					</div>
				}
			</div>
		)
	}
}