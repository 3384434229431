import axios from 'axios';
import {
	API_URL
} from '../config/config';

const axiosInstance = axios.create({
	baseURL: API_URL,
});

axiosInstance.interceptors.request.use(function (config) {
	let data = config.data ? config.data : {};
	let params = config.params ? config.params : {};

	const token = localStorage.getItem('token');

	if (token) {
		data['token'] = token;

		if (config.method === 'get') {
			params['token'] = token;
		}
	}

	config.data = data
	config.params = params

	return config;
}, function (error) {
	return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	return Promise.reject(error);
});

export default axiosInstance